@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  & input::placeholder {
    color: var(--colorGrey500) !important;
  }

  @media (--viewportMedium) {
    justify-content: space-between;
  }
  & label{
    display: none;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}


.heading {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #d46a4d;
}

.inputBox{
  margin-bottom: 24px;
}
.subheading {
  text-align: center;
  color: hsl(0deg 0% 0%);
  font-size: 16px;
  line-height: 22px;
  margin: 3px auto 30px;
  max-width: 250px;
}

.noticeBox {
  background: rgb(254 251 246);
  padding: 16px 36px;
  border-radius: 40px;
  text-align: center;
  margin: 0 auto 20px;
  max-width: 356px;
  & p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: rgb(101 101 104);
    margin: 0;
  }
}