@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  & input::placeholder {
    color: var(--colorGrey500) !important;
  }

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  flex-direction: column;
}

/* .firstNameRoot {
  width: calc(34% - 9px);
}*/

/* .lastNameRoot {
  margin-top: 24px;
}  */

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.selectCountry {
  margin-top: 24px;
}

.phoneNumber {
  margin-top: 24px;
}
.locationAddress {
  margin: 24px 0 24px;
  & input {
    height: 48px;
    @media (max-width: 767px) {
      height: 36px;
    }
  }
}
.unitField {
  width: 150px;
  @media (max-width: 767px) {
    width: 120px;
  }
}
.phoneWrapper {
  margin-top: 24px;
  & .phoneInput {
    position: relative;
    & input {
      padding-left: 100px;
    }
    & .dropdown {
      position: absolute;
      left: 14px;
      top: 5px;
      @media (--viewportMedium) {
        top: 12px;
      }
    }
  }
}
.storeName {
  margin-top: 24px;
}

.container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.highlight {
  color: red;
}

.store {
  font-size: 12px;
  color: gray;
}

.authToggle {
  display: flex;
  justify-content: center;
  gap: 10px;
  font-size: 18px;
  font-weight: bold;
}

.active {
  text-decoration: underline;
}

.inactive {
  color: gray;
}

.heading {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #d46a4d;
}
.inputBox {
  margin-bottom: 24px;
}
.subheading {
  text-align: center;
  color: hsl(0deg 0% 0%);
  font-size: 16px;
  line-height: 22px;
  margin: 3px auto 30px;
  max-width: 250px;
}

.noticeBox {
  background: rgb(254 251 246);
  padding: 20px 40px;
  border-radius: 40px;
  text-align: center;
  margin: 0 auto 20px;
  max-width: 356px;
  & p {
    font-size: 14px;
    line-height: 19px;
    color: rgb(0 0 17);
    margin: 0;
  }

  & .grayText {
    color: rgb(131 132 133);
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.flag {
  font-size: 20px;
}

.phone {
  flex: 1;
  border: none;
  outline: none;
}

.note {
  font-size: 12px;
  color: var(--colorGrey500);
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.countryPlaceholder {
  color: hsl(300deg 1.1% 82.16%) !important;
}

.email {
  margin-top: 24px;
}
